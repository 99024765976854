import * as React from "react";
import SharedHeader from "../../../components/general/components/SharedHeader";
import DirectRewardListing from "../../../components/rewardExplore/pages/DirectRewardListing";

// markup
const Login = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <DirectRewardListing />
    </React.Fragment>
  );
};

export default Login;
